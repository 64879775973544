












































































import { defineComponent, ref, computed } from "@vue/composition-api";
import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import dayjs from "dayjs";
import {
  OrgHeaderInfo,
  AtomText,
  OrgTable,
  AtomDate,
  AtomMoney,
  MolPagination,
  IOrgTableProps,
} from "@/v2/new-design-system";
import {
  fetchTipTransactions,
  IFetchTipTransactionsResponse,
  IFetchTipTransactionsRequest,
} from "@/v2/repo/tips/fetch-tip-transactions/index";
import { IFetchTipStatsResponse } from "@/v2/repo/tips/fetch-tip-stats/index";
import { fetchTipStats } from "@/v2/repo/tips/fetch-tip-stats";
import { TipsFilters } from "../filters";
import { TipsStats } from "../stats";
import { useHelpers } from "@/v2/composable/use-helpers";
import { report } from "@chatfood/bug-reporter";
import { CurrencyCodeEnum } from "@/v2/enum";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("tips-main");

type dataType = IFetchTipTransactionsResponse["data"][0] & {
  uniqueId: string;
  percentage: string;
};

type IFilters = {
  startDate: Date;
  endDate: Date;
  outletId?: string;
  service?: "oat" | "pat";
};

export default defineComponent({
  name: "TipsMain",
  components: {
    OrgHeaderInfo,
    AtomText,
    OrgTable,
    AtomDate,
    AtomMoney,
    MolPagination,
    TipsFilters,
    TipsStats,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const defaultStats = {
      tipCount: 0,
      totalTipAmount: {
        value: 0,
        currency: CurrencyCodeEnum.AED,
      },
      totalTipPercentage: 0,
    };
    const tipStatsData = ref<IFetchTipStatsResponse>(defaultStats);
    const tipsDataState = ref<dataType[]>([]);
    const pagination = ref<IFetchTipTransactionsResponse["meta"]>({
      currentPage: 1,
      lastPage: 1,
      totalRecords: 0,
      perPage: 10,
    });
    const isLoadingList = ref(false);

    const outletsFromBusiness = computed(() =>
      useHelpers().getOutletsFromBusiness(props.businessId)
    );

    const outletsList = computed(() =>
      outletsFromBusiness.value.map((outlet) => ({
        id: outlet.id,
        name: outlet.name,
      }))
    );

    const servicesList = computed(() => [
      { id: "oat", name: t("module.tips.main.service_oat") },
      { id: "pat", name: t("module.tips.main.service_pat") },
    ]);

    const allOutletsIds = computed(() =>
      outletsFromBusiness.value.map((outlet) => outlet.id)
    );

    const initialStartDate = dayjs()
      .startOf("day")
      .subtract(30, "days")
      .toDate();
    const initialEndDate = dayjs().endOf("day").toDate();

    const initialFilters: IFetchTipTransactionsRequest = {
      businessId: props.businessId,
      startDate: initialStartDate,
      endDate: initialEndDate,
      outletIds: allOutletsIds.value,
      page: 1,
    };

    const filters = ref<IFetchTipTransactionsRequest>(initialFilters);

    const columns = computed<IOrgTableProps["columns"]>(() => ({
      recordedAt: {
        header: () => t("module.tips.main.table_date_time"),
        show: true,
        sortable: false,
        sortDirection: "DESC",
      },
      service: {
        header: () => t("module.tips.main.table_service"),
        show: true,
        sortable: false,
        sortDirection: "DESC",
      },
      outletName: {
        header: () => t("module.tips.main.table_outlet"),
        show: true,
        sortable: false,
        sortDirection: "DESC",
      },
      tableName: {
        header: () => t("module.tips.main.table_table_name"),
        show: true,
        sortable: false,
        sortDirection: "DESC",
      },
      tipAmount: {
        header: () => t("module.tips.main.table_total_amount"),
        show: true,
        sortable: false,
        sortDirection: "DESC",
      },
      percentage: {
        header: () => t("module.tips.main.table_percentage"),
        show: true,
        sortable: false,
        sortDirection: "DESC",
      },
    }));

    async function updateTipsDataBasedOnFilters(page: number = 1) {
      isLoadingList.value = true;
      try {
        const [transactionResponse, statsResponse] = await Promise.all([
          fetchTipTransactions({
            ...filters.value,
            page,
          }),
          fetchTipStats(filters.value),
        ]);

        tipsDataState.value = transactionResponse.data.map(
          (transaction, index) => ({
            ...transaction,
            uniqueId: index.toString() + new Date().valueOf().toString(),
            percentage: (
              (transaction.tipAmount.value /
                transaction.totalTransactionAmount.value) *
              100
            ).toFixed(0),
          })
        );

        tipStatsData.value = statsResponse;

        pagination.value = transactionResponse.meta;
      } catch (e) {
        report(e);
        tipsDataState.value = [];
        tipStatsData.value = defaultStats;
        generalErrorToast();
      } finally {
        isLoadingList.value = false;
      }
    }

    function getServiceLabel(service: dataType["service"]): string {
      if (service === "oat") return t("module.tips.main.service_oat");
      if (service === "pat") return t("module.tips.main.service_pat");
      return t("module.tips.main.unknown_service");
    }

    function handlePagination(page: number) {
      updateTipsDataBasedOnFilters(page);
    }

    function onClearFilters() {
      filters.value = initialFilters;
      tipStatsData.value = defaultStats;
      updateTipsDataBasedOnFilters(1);
    }

    function onApplyFilters(val: IFilters) {
      filters.value = {
        ...filters.value,
        outletIds: val.outletId ? [val.outletId] : allOutletsIds.value,
        service: val.service ? val.service : undefined,
        startDate: val.startDate,
        endDate: val.endDate,
      };
      updateTipsDataBasedOnFilters(1);
    }

    return {
      t,
      css,
      tipsDataState,
      columns,
      getServiceLabel,
      pagination,
      outletsFromBusiness,
      allOutletsIds,
      isLoadingList,
      handlePagination,
      onClearFilters,
      onApplyFilters,
      outletsList,
      servicesList,
      tipStatsData,
    };
  },
});
