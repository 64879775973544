import { http } from "../../http";
import { AxiosResponse } from "axios";
import {
  IFetchTipStatsRequest,
  IFetchTipStatsResponse,
  IFetchTipStatsContract,
} from "./types";
import { CurrencyCodeEnum } from "@/v2/enum";

export class FetchTipStatsError extends Error {}

function getServiceId(slug?: "oat" | "pat"): 1 | 2 | undefined {
  if (slug === "oat") return 1;
  if (slug === "pat") return 2;
  return undefined;
}

export async function fetchTipStats(
  request: IFetchTipStatsRequest
): Promise<IFetchTipStatsResponse> {
  let response: AxiosResponse<IFetchTipStatsContract>;
  try {
    response = await http.get(
      `/api/v3/tip-transactions-stats-for-business/${request.businessId}`,
      {
        params: {
          start_date: request.startDate.toISOString(),
          end_date: request.endDate.toISOString(),
          outlet_ids: request.outletIds,
          type: getServiceId(request.service),
          table_ids: request.tableIds,
        },
      }
    );

    const tipsCalcPercentage =
      response.data.total_tip_percentage / response.data.tip_count;

    return {
      tipCount: response.data.tip_count,
      totalTipAmount: response.data.total_tip_amount,
      totalTipPercentage: tipsCalcPercentage,
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    if (e.response.status === 404) {
      return {
        tipCount: 0,
        totalTipAmount: {
          value: 0,
          currency: CurrencyCodeEnum.AED,
        },
        totalTipPercentage: 0,
      };
    }

    throw new FetchTipStatsError(e);
  }
}
