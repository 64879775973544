var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('header')},[_c('OrgHeaderInfo',{attrs:{"heading":_vm.t('module.tips.main.title')}})],1),_c('div',[_c('TipsFilters',{class:_vm.css('filter'),attrs:{"outlets-list":_vm.outletsList,"services-list":_vm.servicesList},on:{"clear":_vm.onClearFilters,"apply":_vm.onApplyFilters}})],1),_c('div',[(_vm.tipStatsData)?_c('TipsStats',{attrs:{"average-percentage":_vm.tipStatsData.totalTipPercentage,"total-tips-collected":_vm.tipStatsData.totalTipAmount,"tipped-transactions-count":_vm.tipStatsData.tipCount}}):_vm._e()],1),_c('div',[_c('OrgTable',{attrs:{"loading":_vm.isLoadingList,"columns":_vm.columns,"total-records":_vm.pagination.perPage,"data":_vm.tipsDataState,"no-records-msg":_vm.t('module.tips.main.table_no_data')},scopedSlots:_vm._u([{key:"recordedAt",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomDate',{attrs:{"datetime":cell,"type":"custom","format":"h:mma, DD MMM YYYY","tag":"span"}})],1)]}},{key:"service",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(_vm.getServiceLabel(cell))+" ")])]}},{key:"tableName",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"outletName",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"tipAmount",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"percentage",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(" "+_vm._s(cell)+"% ")])]}}])}),_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"total":_vm.pagination.totalRecords,"on-navigate":_vm.handlePagination,"per-page":_vm.pagination.perPage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }