



























import { toMoney } from "@chatfood/core-utils";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { MolMetricBox } from "@/v2/new-design-system";
import { t } from "@/i18n";
import { Currency } from "dinero.js";

const css = bemBuilder("tips-stats");

type IPrice = {
  value: number;
  currency: Currency;
};

export default defineComponent({
  name: "TipsStats",
  components: { MolMetricBox },
  props: {
    totalTipsCollected: {
      type: Object as PropType<IPrice>,
      required: true,
    },
    averagePercentage: {
      type: Number,
      required: true,
    },
    tippedTransactionsCount: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      t,
      css,
      toMoney,
    };
  },
});
