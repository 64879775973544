import { http } from "../../http";
import {
  IFetchTipTransactionsContract,
  IFetchTipTransactionsRequest,
  IFetchTipTransactionsResponse,
} from "./types";
import { AxiosResponse } from "axios";

export class FetchTipTransactionsError extends Error {}

function getServiceSlug(
  type: number
): IFetchTipTransactionsResponse["data"][0]["service"] {
  if (type === 1) return "oat";
  if (type === 2) return "pat";
  return undefined;
}

function getServiceId(slug?: "oat" | "pat"): 1 | 2 | undefined {
  if (slug === "oat") return 1;
  if (slug === "pat") return 2;
  return undefined;
}

export async function fetchTipTransactions(
  request: IFetchTipTransactionsRequest
): Promise<IFetchTipTransactionsResponse> {
  let response: AxiosResponse<IFetchTipTransactionsContract>;

  try {
    if (request.sort && !["+", "-"].includes(request.sort[0])) {
      throw new Error("A sort key must start with - or +");
    }

    response = await http.get(
      `/api/v3/tip-transactions-for-business/${request.businessId}`,
      {
        params: {
          start_date: request.startDate.toISOString(),
          end_date: request.endDate.toISOString(),
          outlet_ids: request.outletIds,
          page: request.page,
          type: getServiceId(request.service),
          sort: "-start_date",
        },
      }
    );

    return {
      data: response.data.data.map((record) => {
        return {
          totalTransactionAmount: record.amount,
          service: getServiceSlug(record.type),
          outletName: record.outlet_name,
          recordedAt: new Date(record.recorded_at),
          tableName: record.table_name,
          tipAmount: record.tip_amount,
        };
      }),
      meta: {
        currentPage: response.data.meta.current_page,
        lastPage: response.data.meta.last_page,
        totalRecords: response.data.meta.total,
        perPage: response.data.meta.per_page,
      },
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTipTransactionsError(e);
  }
}
