















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import {
  MolMultiselect,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomDatepickerRange,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import dayjs from "dayjs";

const css = bemBuilder("tips-filters");

type IList = {
  id: string;
  name: string;
};

type IState = {
  startDate: Date;
  endDate: Date;
  outlet?: IList;
  service?: IList;
};

export default defineComponent({
  name: "TipsFilters",
  components: {
    MolMultiselect,
    AtomButton,
    AtomDatepickerRange,
  },
  props: {
    outletsList: {
      type: Array as PropType<IList[]>,
      required: true,
    },
    servicesList: {
      type: Array as PropType<IList[]>,
      required: true,
    },
  },
  emits: ["clear", "apply"],
  setup(_, { emit }) {
    const defaultState = {
      startDate: dayjs().subtract(30, "days").endOf("day").toDate(),
      endDate: dayjs().endOf("day").toDate(),
    };

    const state = ref<IState>(defaultState);

    // Set reference day to today
    let referenceDay = dayjs();

    const onClearFilters = () => {
      state.value = defaultState;
      emit("clear");
    };

    const onApplyFilters = () => {
      emit("apply", {
        startDate: state.value.startDate,
        endDate: state.value.endDate,
        outletId: state.value.outlet?.id,
        service: state.value.service?.id,
      });
    };

    function handleOutletInput(value?: IList) {
      state.value = {
        ...state.value,
        outlet: value,
      };
    }

    function handleDateInput(value: any) {
      state.value = {
        ...state.value,
        startDate: new Date(value.start),
        endDate: new Date(value.end),
      };
    }

    function handleServiceInput(value?: IList) {
      state.value = {
        ...state.value,
        service: value,
      };
    }

    onApplyFilters();

    return {
      t,
      css,
      state,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      onClearFilters,
      onApplyFilters,
      handleOutletInput,
      handleDateInput,
      handleServiceInput,
      referenceDay,
    };
  },
});
